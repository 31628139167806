@font-face
	font-family: DobraSlabBook
	src: url("./assets/fonts/DobraSlab-Book.otf") format("opentype")

@font-face
	font-family: DobraSlabMedium
	src: url("./assets/fonts/DobraSlab-Medium.otf") format("opentype")

@font-face
	font-family: Avenir
	src: url("./assets/fonts/AvenirLTStd-Black.otf") format("opentype")
	font-weight: bold

@font-face
	font-family: Avenir
	src: url("./assets/fonts/AvenirLTStd-Roman.otf") format("opentype")
	font-weight: normal

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap")

body
	background-color: #0090E3
	margin: 0
	width: 100vw
	height: 100vh

	.full-screen
		flex-grow: 1
		// width: 100%
		// height: 100%
	
	.screen
		// width: 100%
		// height: 100%
		display: flex
		flex-grow: 1

	.center-flex
		display: flex
		flex-direction: column
		align-content: center
		align-items: center
		justify-content: center

	.width-80-percent
		width: 80%

	#root
		user-select: none
		position: fixed
		height: 100%
		width: 100%
		display: flex
		flex-direction: column
		overflow: hidden

		.hidden
			display: none !important

		#render-canvas
			position: fixed
			width: 100%
			height: 100%
			z-index: -1

		#permissions-denied
			color: white
			z-index: 10

			@import "permissionsDenied"

		#content
			color: white
			z-index: 2
			display: flex
			.full-screen
				position: fixed
				width: 100%
				height: 100%

			@import "content"

		#camera-loading
			position: fixed
			width: 100%
			height: 100%
			z-index: 10
			background-color: #0090E3
			gap: 15%

			.anim
				transform: scale(1)
				animation: pulse 2s infinite

			@keyframes pulse
				0%
					transform: scale(0.95)

				70%
					transform: scale(1)

				100%
					transform: scale(0.95)

			.loading 
				margin-top: 2vh
				font-family: 'Open Sans', sans-serif
				font-weight: 500
				font-size: 1.5em
				color: #FFFFFF

		#landscape-warning
			position: fixed
			width: 100%
			height: 100%
			z-index: 10

			// #landscape-warning-content
			background-color: #000000c0
			display: flex
			flex-direction: column
			align-content: center
			align-items: center
			justify-content: center
			height: 100%

			.text-box
				display: inline
				text-align: center
				font-family: sans-serif
				border-radius: 5px
				font-size: 1.2em
				font-weight: 500
				padding: 0.5em
				width: 60%
				background-color: #b1b1b1

		// @media (orientation: landscape)
		// 	#landscape-warning

		#debug
			left: 0
			top: 0
			// height: 100px
			width: 100%
			background-color: #000000
			display: flex
			padding: 5px
			z-index: 2
			#pause-button
				background-color: white
				padding: 2px
				margin-right: 5px
