@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap";
@font-face {
  font-family: DobraSlabBook;
  src: url("DobraSlab-Book.69383750.otf") format("opentype");
}
@font-face {
  font-family: DobraSlabMedium;
  src: url("DobraSlab-Medium.683da94a.otf") format("opentype");
}
@font-face {
  font-family: Avenir;
  src: url("AvenirLTStd-Black.c0efd5ed.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: Avenir;
  src: url("AvenirLTStd-Roman.4ff0e7f3.otf") format("opentype");
  font-weight: normal;
}
body {
  background-color: #0090E3;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
body .full-screen {
  flex-grow: 1;
}
body .screen {
  display: flex;
  flex-grow: 1;
}
body .center-flex {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
body .width-80-percent {
  width: 80%;
}
body #root {
  user-select: none;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
body #root .hidden {
  display: none !important;
}
body #root #render-canvas {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
body #root #permissions-denied {
  color: white;
  z-index: 10;
}
body #root #permissions-denied .ui-fonts {
  text-align: left;
  font-family: DobraSlabBook, DobraSlabMedium, sans-serif;
  font-size: 1em;
}
body #root #permissions-denied .android-samsung-browser {
  background-color: #0090E3;
  background-image: url("cb-snow-bg-20.93ddefd6.png");
  background-position: center;
  background-size: contain;
  color: #FFFFFF;
}
body #root #permissions-denied .android-chrome-browser {
  background-color: #0090E3;
  background-image: url("cb-snow-bg-20.93ddefd6.png");
  background-position: center;
  background-size: contain;
  color: #FFFFFF;
}
body #root #permissions-denied .ios-camera-denied {
  background-color: #0090E3;
  background-image: url("cb-snow-bg-20.93ddefd6.png");
  background-position: center;
  background-size: contain;
}
body #root #permissions-denied .coolblue-logo {
  position: fixed;
  top: 0;
  right: 0;
  width: 24vh;
  height: 24vh;
}
body #root #permissions-denied .collection {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
body #root #permissions-denied .collection .title {
  width: 80vw;
  font-size: 2em;
}
body #root #permissions-denied .collection .subtitle {
  width: 80vw;
  font-size: 1.8em;
}
body #root #permissions-denied .collection .explanation {
  width: 80vw;
  line-height: 1.2em;
  margin-top: 2vh;
}
body #root #permissions-denied .collection .steps {
  width: 77.5vw;
  line-height: 1.25em;
  margin-top: 1vh;
}
body #root #permissions-denied .reload-button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  border-bottom: 2px solid #1E4680;
  width: 80vw;
  height: 6vh;
  line-height: 6vh;
  background-color: #285dab;
  border-radius: 5px;
  text-align: center;
  margin-top: 10vh;
}
body #root #content {
  color: white;
  z-index: 2;
  display: flex;
}
body #root #content .full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
}
body #root #content .snow-faded {
  background-image: url("snow2.9c05d5ed.png");
}
body #root #content .snow-anim {
  background-position-y: 0px;
  background-size: contain;
}
body #root #content .disable-button {
  opacity: 0.25;
}
body #root #content .ui-fonts {
  text-align: center;
  font-family: Avenir, sans-serif;
  font-size: 1em;
}
body #root #content #opening-ui {
  background-color: #0090E3;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
body #root #content #opening-ui .coolblue-logo {
  width: 30vh;
  height: 30vh;
}
body #root #content #opening-ui .eighthwall-logo {
  position: absolute;
  bottom: 2em;
  width: 35%;
  height: 4em;
  background-image: url(8thwall-white.34da2304.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
body #root #content #language-select {
  background-color: #0090E3;
}
body #root #content #language-select .button-collection {
  top: 7.5vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
body #root #content #language-select .coolblue-logo {
  position: fixed;
  top: 0;
  right: 0;
  width: 24vh;
  height: 24vh;
}
body #root #content #language-select .margin {
  margin: 1.25vh;
}
body #root #content #language-select .button {
  background-color: #FFFFFF;
  border-radius: 3px;
  width: 80vw;
  height: 9vh;
  line-height: 9vh;
  border-bottom: 2px solid #DDD;
  color: #0090E3;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: left;
  text-indent: 3%;
}
body #root #content #language-select .button .flag, body #root #content #language-select .button .arrow {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 2.5vh;
}
body #root #content #language-select .button .flag {
  float: left;
  margin-left: 5vw;
}
body #root #content #language-select .button .arrow {
  float: right;
  margin-right: 5vw;
}
body #root #content #model-ui .scan-button {
  width: 2em;
  height: 2em;
}
body #root #content #model-ui .image {
  position: relative;
  width: 15vw;
  height: 15vw;
}
body #root #content #model-ui #bottom-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 3em;
}
body #root #content #model-ui #bottom-section #back-button {
  position: absolute;
  left: 2em;
  top: 2em;
}
body #root #content #model-ui #bottom-section #scanning-helper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}
body #root #content #model-ui #bottom-section .scan-target-bg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
}
body #root #content #model-ui #bottom-section .scan-target {
  padding: 1em;
  width: 100%;
  border-radius: 1em;
  border-width: 0.33em;
  border-color: #ffffff;
  border-style: solid;
}
@media (orientation: landscape) {
  body #root #content #model-ui #bottom-section .scan-target {
    width: 30%;
  }
}
body #root #content #model-ui #bottom-section .scan-target:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
body #root #content #model-ui #bottom-section .vink {
  background-image: url("vinkje.5c8121d7.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  width: 25%;
  height: 25%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: relative;
}
body #root #content #model-ui #bottom-section .top-marker {
  background-image: url("top.9b56901c.png");
}
body #root #content #model-ui #bottom-section .top-marker-ok {
  background-image: url("top.9b56901c.png");
  width: 100%;
  height: 100%;
}
body #root #content #model-ui #bottom-section .test-2-marker {
  background-image: url("test-2.73fa89a5.png");
}
body #root #content #model-ui #bottom-section .test-2-marker-ok {
  background-image: url("test-2.73fa89a5.png");
  width: 100%;
  height: 100%;
}
body #root #content #model-ui #bottom-section .busje-marker {
  background-image: url("busje.8b68fc72.png");
}
body #root #content #model-ui #bottom-section .busje-marker-ok {
  background-image: url("busje.8b68fc72.png");
  width: 100%;
  height: 100%;
}
body #root #content #model-ui #bottom-section .test-3-marker {
  background-image: url("test-3.17bee999.png");
}
body #root #content #model-ui #bottom-section .test-3-marker-ok {
  background-image: url("test-3.17bee999.png");
  width: 100%;
  height: 100%;
}
body #root #content #model-ui #bottom-section .windmolen-marker {
  background-image: url("windmolen.395d3abd.png");
}
body #root #content #model-ui #bottom-section .windmolen-marker-ok {
  background-image: url("windmolen.395d3abd.png");
  width: 100%;
  height: 100%;
}
body #root #content #model-ui #bottom-section .info-box {
  background-color: #FFFFFF;
  color: #0090E3;
  border-radius: 3px;
  position: relative;
  flex: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 100%;
}
body #root #content #model-ui #bottom-section #scanning-label {
  margin-bottom: -1.2em;
}
body #root #content #model-ui #bottom-section #scanning-amount {
  margin-top: -1.2em;
  font-weight: bold;
}
body #root #content #model-ui #replay-section {
  position: fixed;
  width: 100vw;
  bottom: 7vh;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
  justify-content: center;
}
body #root #content #model-ui #replay-section .margin {
  margin-top: 1vh;
}
body #root #content #tutorial-ui {
  background-color: #0090E3;
}
body #root #content #tutorial-ui #back-to-ls {
  position: fixed;
  left: 10vw;
  top: 7vh;
  width: 3.25vh;
  height: 3.25vh;
}
body #root #content #tutorial-ui .coolblue-logo {
  position: fixed;
  top: 0;
  right: 0;
  width: 24vh;
  height: 24vh;
}
body #root #content #tutorial-ui .collection {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: left;
}
body #root #content #tutorial-ui .collection .title {
  width: 80vw;
  font-size: 2em;
  line-height: 100%;
}
body #root #content #tutorial-ui .collection .subtitle {
  width: 80vw;
  font-size: 1.8em;
  font-family: DobraSlabBook;
  color: #CCE9FF;
  line-height: 100%;
}
body #root #content #tutorial-ui .collection .body {
  font-family: "Open Sans", sans-serif;
  width: 80vw;
  line-height: 1.25em;
  margin-top: 2vh;
}
body #root #content #tutorial-ui .collection #start-button {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  border-bottom: 2px solid #1E4680;
  margin-top: 10vh;
  text-align: center;
  width: 80vw;
  height: 6vh;
  line-height: 6vh;
  background-color: #285dab;
  border-radius: 5px;
}
body #root #camera-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #0090E3;
  gap: 15%;
}
body #root #camera-loading .anim {
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
body #root #camera-loading .loading {
  margin-top: 2vh;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 1.5em;
  color: #FFFFFF;
}
body #root #landscape-warning {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #000000c0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body #root #landscape-warning .text-box {
  display: inline;
  text-align: center;
  font-family: sans-serif;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 500;
  padding: 0.5em;
  width: 60%;
  background-color: #b1b1b1;
}
body #root #debug {
  left: 0;
  top: 0;
  width: 100%;
  background-color: #000000;
  display: flex;
  padding: 5px;
  z-index: 2;
}
body #root #debug #pause-button {
  background-color: white;
  padding: 2px;
  margin-right: 5px;
}
/*# sourceMappingURL=index.92c1534c.css.map */
