.ui-fonts
	text-align: left
	font-family: DobraSlabBook, DobraSlabMedium, sans-serif
	font-size: 1em

.android-camera

.android-samsung-browser		
	background-color: #0090E3
	background-image: url("../static/ui/cb-snow-bg-20.png")
	background-position: center
	background-size: contain
	color: #FFFFFF

.android-chrome-browser
	background-color: #0090E3
	background-image: url("../static/ui/cb-snow-bg-20.png")
	background-position: center
	background-size: contain
	color: #FFFFFF

.ios-camera-denied
	background-color: #0090E3
	background-image: url("../static/ui/cb-snow-bg-20.png")
	background-position: center
	background-size: contain

.ios-motion-denied-12 // ios 12

.ios-motion-denied
		
.coolblue-logo
	position: fixed
	top: 0
	right: 0
	width: 24vh
	height: 24vh

.collection
	width: 100vw
	height: 100vh
	display: flex
	flex-direction: column
	align-content: center
	align-items: center
	justify-content: center

	.title
		width: 80vw
		font-size: 2em

	.subtitle
		width: 80vw
		font-size: 1.8em

	.explanation
		width: 80vw
		line-height: 1.2em
		margin-top: 2vh

	.steps 
		width: 77.5vw
		line-height: 1.25em
		margin-top: 1vh

.reload-button
	font-family: 'Open Sans', sans-serif
	font-weight: 700
	border-bottom: 2px solid #1E4680
	width: 80vw
	height: 6vh
	line-height: 6vh
	background-color: #285dab
	border-radius: 5px
	text-align: center
	margin-top: 10vh