// @font-face 
// 	font-family: DobraSlabBook
// 	src: url("./assets/fonts/Dobra Slab W01 Book.ttf") format("ttf")

// @font-face 
// 	font-family: DobraSlabMedium
// 	src: url("./assets/fonts/Dobra Slab W01 Medium.ttf") format("ttf")

.snow-faded
	background-image: url("./assets/snow2.png")

.snow-anim
	background-position-y: 0px
	background-size: contain

.disable-button
	opacity: 0.25

.ui-fonts
	text-align: center
	font-family: Avenir, sans-serif
	font-size: 1em

#opening-ui
	background-color: #0090E3
	display: flex
	flex-direction: column
	align-content: center
	align-items: center
	justify-content: center

	.coolblue-logo
		width: 30vh
		height: 30vh
	
	.eighthwall-logo
		position: absolute
		bottom: 2em
		width: 35%
		height: 4em
		background-image: url(./assets/8thwall-white.png)
		background-repeat: no-repeat
		background-position: center
		background-size: contain 

#language-select
	background-color: #0090E3

	.button-collection
		top: 7.5vh
		display: flex
		flex-direction: column
		align-content: center
		align-items: center
		justify-content: center

	.coolblue-logo
		position: fixed
		top: 0
		right: 0
		width: 24vh
		height: 24vh

	.margin
		margin: 1.25vh

	.button
		background-color: #FFFFFF
		border-radius: 3px
		width: 80vw
		height: 9vh
		line-height: 9vh
		border-bottom: 2px solid #DDD
		color: #0090E3
		font-family: 'Open Sans', sans-serif
		font-weight: bold
		text-align: left
		text-indent: 3%

		.flag, .arrow
			position: relative
			top: 50%
			transform: translateY(-50%)
			height: 2.5vh

		.flag
			float: left
			margin-left: 5vw

		.arrow
			float: right
			margin-right: 5vw

#model-ui
	.scan-button
		width: 2em
		height: 2em

	.image
		position: relative
		width: 15vw
		height: 15vw

	// #top-section
	// 	position: fixed
	// 	width: 100vw
	// 	top: 7vh

	#bottom-section
		// position: fixed
		// width: 100vw
		// bottom: 8vh
		display: flex
		flex-direction: column
		align-content: center
		align-items: center
		// justify-content: center
		margin: 3em

		#back-button
			position: absolute
			left: 2em
			top: 2em

		#scanning-helper
			display: flex
			flex-grow: 1
			flex-direction: column
			align-content: center
			align-items: center
			justify-content: center
			width: 100%

		.scan-target-bg
			background-size: contain
			background-repeat: no-repeat
			background-position: center
			background-origin: content-box
		.scan-target
			padding: 1em
			width: 100%
			border-radius: 1em
			border-width: 0.33em
			border-color: #ffffff
			border-style: solid
			// display: flex
			// align-content: center
			// align-items: center
			// justify-content: center
			// mix-blend-mode: normal
			// height: 80%

		@media (orientation: landscape)
			.scan-target
				width: 30%

		.scan-target:after
			content: ""
			display: block
			padding-bottom: 100%
			// mask-size: contain
			// mask-repeat: no-repeat
			// mask-position: center
		// .ok:after
		// 	background-color: #0f0
			// mix-blend-mode: darken
		
		.vink
			background-image: url("./assets/markers/new/vinkje.png")
			background-size: contain
			background-repeat: no-repeat
			background-position: center
			background-origin: content-box
			width: 25%
			height: 25%
			left: 50%
			top: 50%
			transform: translateX(-50%) translateY(-50%)
			position: relative
		.top-marker
			background-image: url("./assets/markers/new/top.png")
		.top-marker-ok
			background-image: url("./assets/markers/new/top.png")
			width: 100%
			height: 100%
		// #top-marker:after
		// 	mask-image: url("./assets/markers/top.png")
		.test-2-marker
			background-image: url("./assets/markers/new/test-2.png")
		.test-2-marker-ok
			background-image: url("./assets/markers/new/test-2.png")
			width: 100%
			height: 100%
		// #test-2-marker:after
		// 	mask-image: url("./assets/markers/test-2.png")
		.busje-marker
			background-image: url("./assets/markers/new/busje.png")
		.busje-marker-ok
			background-image: url("./assets/markers/new/busje.png")
			width: 100%
			height: 100%
		// #busje-marker:after
		// 	mask-image: url("./assets/markers/busje.png")
		.test-3-marker
			background-image: url("./assets/markers/new/test-3.png")
		.test-3-marker-ok
			background-image: url("./assets/markers/new/test-3.png")
			width: 100%
			height: 100%
		// #test-3-marker:after
		// 	mask-image: url("./assets/markers/test-3.png")
		.windmolen-marker
			background-image: url("./assets/markers/new/windmolen.png")
		.windmolen-marker-ok
			background-image: url("./assets/markers/new/windmolen.png")
			width: 100%
			height: 100%
		// #windmolen-marker:after
		// 	mask-image: url("./assets/markers/windmolen.png")

		.info-box
			background-color: #FFFFFF
			color: #0090E3
			border-radius: 3px
			position: relative
			flex: 0
			//width: 68vw
			font-family: 'Open Sans', sans-serif
			font-size: 1.2em
			padding-top: 0.5em
			padding-bottom: 0.5em
			padding-left: 1em
			padding-right: 1em
			display: flex
			align-content: center
			align-items: center
			justify-content: center
			flex-direction: column
			line-height: 100%

		#scanning-label
			margin-bottom: -1.2em

		#scanning-amount
			margin-top: -1.2em			
			font-weight: bold

		// .margin
		// 	margin-left: 6vw

	#replay-section
		position: fixed
		width: 100vw
		bottom: 7vh
		display: flex
		flex-direction: column
		align-content: flex-end
		align-items: center
		justify-content: center

		.margin
			margin-top: 1vh

#tutorial-ui
	background-color: #0090E3

	#back-to-ls
		position: fixed
		left: 10vw
		top: 7vh
		width: 3.25vh
		height: 3.25vh

	.coolblue-logo
		position: fixed
		top: 0
		right: 0
		width: 24vh
		height: 24vh

	.collection
		margin-top: 10vh
		display: flex
		flex-direction: column
		align-content: center
		align-items: center
		justify-content: center
		text-align: left

		.title
			width: 80vw
			font-size: 2em
			line-height: 100%

		.subtitle
			width: 80vw
			font-size: 1.8em
			font-family: DobraSlabBook
			color: #CCE9FF
			line-height: 100%

		.body
			font-family: 'Open Sans', sans-serif
			width: 80vw
			line-height: 1.25em
			margin-top: 2vh			

		#start-button
			font-family: 'Open Sans', sans-serif
			font-weight: bold
			border-bottom: 2px solid #1E4680
			margin-top: 10vh
			text-align: center
			width: 80vw
			height: 6vh
			line-height: 6vh
			background-color: #285dab
			border-radius: 5px
